import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { Logo } from "../Icons";
import { useAtom, useAtomValue } from "jotai";
import { isSmallAtom, triggerRefreshAtom } from "../../atoms";
import { MobileNavMenu } from "./MobileNavMenu";
import { navbarData } from "./navbarData";

const NavbarLink = ({
  children,
  className,
  active,
  lightTheme = false,
  ...restProps
}: {
  to: string;
  children: React.ReactNode | string;
  target?: string;
  className?: string;
  active?: boolean;
  lightTheme?: boolean;
}) => {
  return (
    <NavLink
      className={twMerge(
        classNames(
          "transition font-bold place-self-center",
          {
            "border-b-2 cursor-default": active,
            "text-black border-black": lightTheme,
            "md:hover:text-textDarkBgAlt": !active && !lightTheme,
            "md:hover:text-hoverGray": !active && lightTheme,
          },
          className
        )
      )}
      {...restProps}
    >
      {children}
    </NavLink>
  );
};

export const Navbar = () => {
  const location = useLocation();
  const altTheme = location.pathname === "/resources";
  const isSmall = useAtomValue(isSmallAtom);
  const [triggerRefresh, setTriggerRefresh] = useAtom(triggerRefreshAtom);

  const LogoLink = () => (
    <NavLink
      to="/"
      className="grid content-center"
      onClick={() => setTriggerRefresh(!triggerRefresh)}
    >
      <button className={classNames("transition", { invert: altTheme })}>
        <Logo
          className={classNames("transition fill-none stroke-white", {
            "md:hover:stroke-textDarkBgAlt": !altTheme,
            "md:hover:stroke-hoverGray": altTheme,
          })}
          width={40}
          height={40}
        />
      </button>
    </NavLink>
  );

  const Title = () => (
    <div
      className={classNames(
        { "col-span-2 z-[10]": isSmall },
        "text-center font-bold leading-tight md:text-xl grid content-center"
      )}
    >
      <button
        className={classNames("transition", {
          "md:hover:text-textDarkBgAlt": !altTheme,
          "md:hover:text-hoverGray text-black": altTheme,
        })}
      >
        <NavLink to="/" onClick={() => setTriggerRefresh(!triggerRefresh)}>
          {!isSmall ? (
            "Explore Old Dubbo Gaol"
          ) : (
            <span>
              Explore
              <br />
              Old Dubbo Gaol
            </span>
          )}
        </NavLink>
      </button>
    </div>
  );

  const PageLinksDesktop = () => (
    <div className="flex gap-10 justify-end">
      {navbarData.map((link, index) => (
        <NavbarLink
          to={link.to}
          key={index}
          active={location.pathname === link.to}
          lightTheme={altTheme}
        >
          {link.label}
        </NavbarLink>
      ))}
    </div>
  );

  return (
    <div
      className={classNames(
        "transition sticky top-0 w-full h-12 grid content-center px-3 md:px-6 z-20",
        {
          "text-textDarkBg": !altTheme,
          "text-textLightBg": altTheme,
        }
      )}
    >
      <div
        className={twMerge(
          classNames("grid", {
            "grid-cols-3": !isSmall,
            "grid-cols-4": isSmall,
          })
        )}
      >
        <LogoLink />
        <Title />
        {isSmall ? <MobileNavMenu altTheme={altTheme} /> : <PageLinksDesktop />}
      </div>
    </div>
  );
};
