import classNames from "classnames";
import { useEffect, useState } from "react";
import { VideoIcon, FeaturedIcon } from "../Icons";
import { mergeClass } from "../../utils";
import _ from "lodash";

interface IMarkerProps {
  xpos: number;
  ypos: number;
  curScale: number;
  nodeType?: string;
  title?: string;
  onClick?: any; // function
  filtered?: boolean;
  isSelected?: boolean;
  isSmall?: boolean;
}

const delayStyle = (ypos: number, xpos: number) => {
  return {
    animationDelay: `${ypos * 0.3}ms`,
    animationFillMode: "backwards",
  };
};

const MarkerLine = ({ xpos, ypos, curScale }: IMarkerProps): JSX.Element => {
  const style = {
    transform: `translate(${xpos}px, ${ypos}px) scale(${1 / curScale})`,
  };
  return (
    <div style={style} className="relative h-0 w-0">
      <div
        className="animate animate-markerDrop"
        style={delayStyle(ypos, xpos)}
      >
        <div className="absolute w-[2px] h-6 transform -translate-x-1/2 bg-black -translate-y-full -z-10" />
      </div>
    </div>
  );
};

const MarkerTitle = ({
  xpos,
  ypos,
  title,
  nodeType,
  onClick,
  isSelected,
  curScale,
}: IMarkerProps & { isSelected: boolean }): JSX.Element => {
  const [reveal, setReveal] = useState(false);
  const [textWidth, setTextWidth] = useState<number>(400);
  const style = {
    transform: `translate(${xpos}px, ${ypos}px) scale(${1 / curScale})`,
  };

  const handleClick = () => {
    if (!reveal) setReveal(true);
    else onClick();
  };

  useEffect(() => {
    const wd = document.getElementById(`marker_${title}`)?.clientWidth;
    setTextWidth(wd || 400);
  }, []);

  const Icon = nodeType === "video" ? VideoIcon : FeaturedIcon;
  const open = reveal || isSelected;

  const iconClassOptions = {
    "fill-white": !open,
    "fill-black": open,
  };

  const bgClassOptions = {
    "bg-white": open,
    "bg-black": !open && nodeType !== "video",
    "bg-videoIcon": !open && nodeType === "video",
  };

  return (
    <div
      id={`node_${title}`}
      style={style}
      className={classNames("relative w-0 h-0 transform", {
        "z-20": open,
      })}
    >
      <div
        className="absolute w-0 h-0 animate animate-markerDrop"
        style={delayStyle(ypos, xpos)}
      >
        <div
          className={classNames(
            "group pointer-events-auto w-10 h-10 md:w-10 md:h-10 relative grid content-center",
            "transform -translate-x-1/2 -translate-y-14 md:-translate-y-16"
          )}
          onMouseOver={() => setReveal(true)}
          onMouseOut={() => setReveal(false)}
        >
          <button
            onClick={() => handleClick()}
            className={mergeClass(
              "transition-marker map-marker max-w-fit overflow-hidden rounded-full flex shadow-marker",
              bgClassOptions
            )}
            style={{
              width: open ? `${textWidth + 128}px` : "2.5rem",
            }}
          >
            <div
              className="animate animate-fadeinDelay"
              style={delayStyle(ypos, xpos)}
            >
              <Icon
                width="40px"
                height="40px"
                strokeWidth={0}
                fill={open ? "black" : "white"}
                className="transition"
              />
            </div>
            <div
              id={`marker_${title}`}
              className={classNames(
                "transition left-full text-black whitespace-nowrap text-sm font-bold mr-4 place-self-center",
                {
                  "opacity-100": open,
                  "opacity-0": !open,
                }
              )}
            >
              {title}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export { MarkerLine, MarkerTitle };
