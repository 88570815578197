import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal } from ".";
import { ArrowWelcomeIcon, CloseIcon } from "./Icons";
import parse from "html-react-parser";
import { useAtomValue } from "jotai";
import { isSmallAtom } from "../atoms";
import { ReactComponent as WelcomeLogo } from "../assets/icons/welcome_logo.svg";
import classNames from "classnames";
import { Loader } from "./Loader";
import axios from "axios";

const defaultTitle = "<p>Welcome to <br /> Old Dubbo Gaol</p>";
const defaultBody =
  "<p>The Old Dubbo Gaol would like to acknowledge the Wiradjuri people who are the Traditional Custodians of the Land on which we sit. We ask that you pay respects to Elders both past and present and extend that respect to other Indigenous Australians on country.</p><br /><p> We would also like to acknowledge the Old Dubbo Gaol's history which included the incarceration of Indigenous Australians. Exhibitions on display are presented within a historical context and may contain descriptions and language which is now seen as inappropriate. Exhibitions may include images of deceased Indigenous Australians. </p>";

const WelcomePopup = ({
  closeExternal,
}: {
  closeExternal: Dispatch<SetStateAction<boolean>>;
}) => {
  const isSmall = useAtomValue(isSmallAtom);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{ title: string; body: string }>({
    title: "<p>Welcome to <br /> Old Dubbo Gaol</p>",
    body: defaultBody,
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://lightwell-odg-site-syd.s3.ap-southeast-2.amazonaws.com/endpoints/welcomepopup.json"
      );

      if (response.data) {
        setData({
          title: response.data.title || defaultTitle,
          body: response.data.body || defaultBody,
        });
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const NormalDisplay = () => (
    <div
      className={
        "py-8 md:px-20 md:py-12 flex flex-col text-center gap-5 w-full h-full overflow-y-auto"
      }
    >
      <button
        className="absolute top-2 right-2 group"
        onClick={() => closeExternal(false)}
      >
        <CloseIcon
          fill="none"
          strokeWidth={1}
          width="60px"
          height="60px"
          className="transition stroke-white md:group-hover:stroke-lightGreen"
        />
      </button>
      <div className="w-full flex justify-center h-[100px] animate animate-fadein">
        {/* <img src="/assets/welcome_logo.png" /> */}
        <WelcomeLogo
          fill="white"
          // strokeWidth={1}
          width="100px"
          height="100px"
          className="transition stroke-white md:group-hover:stroke-lightGreen"
        />
      </div>
      <h1 className="text-2xl md:text-3xl font-bold">{parse(data.title)}</h1>
      {!isSmall ? (
        <div>{parse(data.body)}</div>
      ) : (
        <div
          id="body_container"
          className="w-full flex-grow font-medium relative overflow-y-hidden"
        >
          <div
            id="body_text"
            className="w-full px-8 pt-3 pb-8 h-full overflow-y-auto text-white"
          >
            {parse(data.body)}
          </div>
          <div id="top-gradient" className="absolute w-full h-4 left-0 top-0">
            <div className="bg-gradient-to-b from-darkGreen w-full h-full" />
          </div>
          <div
            id="bot-gradient"
            className="absolute w-full h-4 left-0 bottom-0"
          >
            <div className="bg-gradient-to-t from-darkGreen w-full h-full" />
          </div>
        </div>
      )}
      <div className="w-full flex justify-center">
        <button onClick={() => closeExternal(false)} className="group">
          <div className="flex">
            <div className="transition place-self-center font-bold md:group-hover:text-lightGreen">
              Continue
            </div>
            <ArrowWelcomeIcon
              id="no-shadow"
              fill="none"
              strokeWidth={1}
              width="40px"
              height="40px"
              className={classNames(
                "transition stroke-white -rotate-90",
                "md:group-hover:stroke-lightGreen",
                "md:group-hover:translate-x-2"
              )}
            />
          </div>
        </button>
      </div>
    </div>
  );

  return (
    <Modal showModal={true}>
      <Modal.Display tailwindWidth="w-full h-full relative md:h-auto md:max-w-[48rem] bg-darkGreen z-40">
        <Modal.Body>{isLoading ? <Loader /> : <NormalDisplay />}</Modal.Body>
      </Modal.Display>
    </Modal>
  );
};

export default WelcomePopup;
