import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { Navbar } from "./Navbar";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import {
  displayInfoAtom,
  isLandscapeAtom,
  isSmallAtom,
  mapHeight,
  mapWidth,
  mobileWidth,
} from "../../atoms";
import { useEffect } from "react";
import _ from "lodash";
import { isMobile } from "react-device-detect";

export const PageContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element => {
  const location = useLocation();
  const altTheme = location.pathname === "/resources";

  const [, setIsSmall] = useAtom(isSmallAtom);
  const [, setIsLandscape] = useAtom(isLandscapeAtom);
  const [, setDisplayInfo] = useAtom(displayInfoAtom);

  useEffect(() => {
    handleResize();
    window.addEventListener(
      "resize",
      _.debounce(() => handleResize(), 25)
    );

    return () => {
      window.removeEventListener(
        "resize",
        _.debounce(() => handleResize(), 25)
      );
    };
  }, []);

  const checkLandscape = () => {
    const landscape = window.innerWidth > window.innerHeight;
    setIsLandscape(landscape);
  };

  const handleResize = () => {
    isMobile && checkLandscape();

    // display size for map/slides
    const container = document.getElementById("page_container");
    const wd = container?.clientWidth || 1;
    const ht = container?.clientHeight || 1;
    const minScale = _.max([ht / mapHeight, wd / mapWidth]) || wd / mapWidth;
    setDisplayInfo({ wd, ht, minScale });

    setIsSmall(window.innerWidth < mobileWidth);
  };

  return (
    <main
      className={classNames(`transition w-full h-full`, {
        "bg-navBg2": altTheme,
        "bg-navBg1": !altTheme,
      })}
    >
      <Navbar />
      <div
        id="page_container"
        className={twMerge(
          classNames("w-full h-[calc(100%-3rem)] overflow-y-auto", className)
        )}
      >
        {children}
      </div>
    </main>
  );
};
