import { atom } from "jotai";
import _ from "lodash";

interface IDisplayInfo {
  wd: number;
  ht: number;
  minScale: number;
}

const mobileWidth = 800;
const mapWidth = 5302;
const mapHeight = 4426;

const isSmallAtom = atom<boolean>(window.innerWidth < mobileWidth);
const isLandscapeAtom = atom<boolean>(true);
const displayInfoAtom = atom<IDisplayInfo>({
  wd: window.innerWidth,
  ht: window.innerHeight,
  minScale:
    _.max([
      (window.innerHeight - 40) / mapHeight,
      window.innerWidth / mapWidth,
    ]) || window.innerWidth / mapWidth,
});
const triggerRefreshAtom = atom<boolean>(true);
const showWelcomePopupAtom = atom<boolean>(true);

export {
  isSmallAtom,
  isLandscapeAtom,
  mobileWidth,
  displayInfoAtom,
  mapWidth,
  mapHeight,
  triggerRefreshAtom,
  showWelcomePopupAtom,
};
