import { ReactComponent as HatSVG } from "../assets/hat.svg";
import { mergeClass } from "../utils";

const HatLoader = ({
  black,
  className,
}: {
  black?: boolean;
  className?: string;
  isLoading?: boolean;
}) => {
  const color = black ? "black" : "white";
  return (
    <div className={mergeClass("w-[50px] h-[50px] relative", className)}>
      <div className="absolute bottom-0 animate animate-hatBounce">
        <HatSVG
          fill={color}
          // strokeWidth={1}
          width="50px"
          height="50px"
          className={mergeClass("animate animate-hatSpin")}
        />
      </div>
    </div>
  );
};

const DragonBallzLoader = ({ black }: { black?: boolean }) => {
  const color = black ? "bg-black" : "bg-white";
  return (
    <div className="animate-spin w-16 h-16 flex flex-wrap place-content-center justify-center">
      <div className={`rounded-full w-2 h-2 m-2 opacity-50 ${color}`} />
      <div className={`rounded-full w-2 h-2 m-2 opacity-50 ${color}`} />
    </div>
  );
};

const Loader = ({
  isLoading = true,
  children,
  black,
}: {
  isLoading?: boolean;
  black?: boolean;
  children?: React.ReactNode | string;
}) => (
  <>
    {isLoading ? (
      <div className="absolute w-full h-full flex flex-wrap place-content-center justify-center">
        <HatLoader black={black} />
        {/* <DragonBallzLoader /> */}
      </div>
    ) : (
      <div className="w-full h-full">{children}</div>
    )}
  </>
);

export { Loader };
