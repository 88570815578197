import { useState } from "react";
import { CloseIcon, ImageCaptionIcon } from "../Icons";
import parse from "html-react-parser";
import _ from "lodash";
import { CircleButton } from "../Button";

const removeSpanTags = (str: string) => {
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<span([^>]+)>)/gi, "");
};

export const CaptionBlock = ({
  caption,
  credit,
}: {
  caption?: string | undefined;
  credit?: string | undefined;
}) => {
  const [showCaption, setShowCaption] = useState<boolean>(true);
  const noCaption = !caption || caption === "<p><br></p>";
  const noCredit = !credit || credit === "<p><br></p>";
  if (noCredit && noCaption) return null;
  return (
    <div className="absolute bottom-0 left-0 md:bottom-4 md:left-4 w-full z-10 pointer-events-none">
      {!showCaption ? (
        <CircleButton
          className="pointer-events-auto ml-4 mb-4 md:ml-0 md:mb-0"
          onClick={() => setShowCaption(true)}
        >
          <ImageCaptionIcon
            fill="none"
            width={30}
            height={30}
            strokeWidth={2}
            className="stroke-white"
          />
        </CircleButton>
      ) : (
        <div className="animate animate-fadein bg-black/80 w-fit relative pointer-events-auto">
          <button
            className="w-14 h-14 absolute right-3 top-0"
            onClick={() => setShowCaption(false)}
          >
            <CloseIcon
              fill="none"
              strokeWidth={1}
              className="stroke-white md:hover:stroke-lightGreen transition"
            />
          </button>
          <div className="w-full md:w-96 text-white px-8 pt-10 pb-8 pb-6 md:pb-6 space-y-3">
            {!noCaption && <p>{parse(removeSpanTags(_.toString(caption)))}</p>}
            {!noCredit && (
              <p className="italic text-sm">
                {parse(removeSpanTags(_.toString(credit)))}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
