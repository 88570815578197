import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ISlideScrollProps } from "./interfaces";

import { Slide, SocialsSlide } from "./Slides";
import { ArrowIcon } from "../Icons";
import { mergeClass } from "../../utils";

const SlideScroll = ({
  slides,
  className,
  addLastPage = false,
}: ISlideScrollProps) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const handleScroll = () => {
    const newSlideIndex = slides.findIndex((slide, index) => {
      const slideElement = document.getElementById(getSlideId(index));
      if (!slideElement) return false;
      const rect = slideElement.getBoundingClientRect();
      return rect.top + rect.height >= 100;
    });

    if (currentSlideIndex !== newSlideIndex) {
      if (newSlideIndex === -1 && addLastPage)
        setCurrentSlideIndex(slides.length);
      else setCurrentSlideIndex(newSlideIndex);
    }
  };

  const scrollToPage = (slideId: string) => {
    const wrapper = document.getElementById("scroll-wrapper");
    const slide = document.getElementById(slideId);
    if (wrapper && slide)
      wrapper.scrollTo({ top: slide.offsetTop, behavior: "smooth" });
  };

  const getSlideId = (index: number) => `slide_${index}`;

  const PageIndicator = ({ index }: { index: number }): JSX.Element => (
    <div className="h-0 grid place-content-center">
      <button
        onClick={() => scrollToPage(getSlideId(index))}
        className={twMerge(
          classNames("transition rounded-full bg-white w-2.5 h-2.5", {
            "opacity-60": index !== currentSlideIndex,
          })
        )}
      />
    </div>
  );

  const showNextPageButton =
    currentSlideIndex < slides.length - 1 ||
    (addLastPage === true && currentSlideIndex < slides.length);

  const NextPageButton = () => (
    <div className="w-full h-24 absolute bottom-0 grid pointer-events-none">
      <button
        className={mergeClass(
          "md:transition place-self-center group hidden md:block",
          {
            "opacity-100 md:pointer-events-auto": showNextPageButton,
            "opacity-0": !showNextPageButton,
          }
        )}
        onClick={() => scrollToPage(getSlideId(currentSlideIndex + 1))}
      >
        <div className="transition grid mb-4 md:group-hover:text-textDarkBgAlt font-medium">
          {slides[currentSlideIndex + 1]?.title ||
            (addLastPage && "Stay in touch") ||
            "Next Page"}
          <ArrowIcon
            width={40}
            height={40}
            fill="none"
            strokeWidth={1.5}
            className={classNames(
              "transition place-self-center stroke-white",
              "md:group-hover:stroke-textDarkBgAlt md:group-hover:translate-y-2"
            )}
          />
        </div>
      </button>
    </div>
  );

  return (
    <>
      <div
        id="scroll-wrapper"
        className={twMerge(
          classNames(
            "w-full h-full max-h-[100%] relative",
            {
              "scroll-smooth md:overflow-y-scroll md:snap md:snap-y md:snap-mandatory scrollbar-hide":
                slides.length > 1,
            },
            className
          )
        )}
        onScroll={handleScroll}
      >
        {slides.map((slide, index) => (
          <Slide
            slideId={getSlideId(index)}
            title={slide.title}
            description={slide.body}
            imageUrl={slide.imageUrl}
            key={index}
            first={index === 0}
          />
        ))}
        {addLastPage === true && (
          <SocialsSlide slideId={getSlideId(slides.length)} />
        )}
      </div>
      {slides.length > 1 && (
        <>
          <div className="invisible md:visible absolute ml-8 left-0 top-0 z-10 h-full grid content-center gap-6">
            {slides.map((slide, index) => (
              <PageIndicator index={index} key={index} />
            ))}
            {addLastPage === true && <PageIndicator index={slides.length} />}
          </div>
          <NextPageButton />
        </>
      )}
    </>
  );
};

export default SlideScroll;
