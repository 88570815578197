import _ from "lodash";
import parse from "html-react-parser";
import { ISlideProps } from "./interfaces";
import classNames from "classnames";
import {
  SMFacebookIcon,
  SMGoogleIcon,
  SMInstagramIcon,
  SMTikTokIcon,
  SMTripAdvisorIcon,
} from "../Icons";

const SlideOuterContainer = ({
  children,
  slideId,
}: {
  children?: React.ReactNode;
  slideId?: string;
}) => (
  <div
    id={slideId}
    className="animate animate-fadeinSlow w-full h-auto md:min-h-full snap-always snap-start grid content-center py-16"
  >
    <div className="w-full h-full min-h-content flex justify-center">
      {children}
    </div>
  </div>
);

const Slide = ({
  title,
  description,
  first,
  slideId,
  imageUrl,
}: ISlideProps): JSX.Element => {
  const Title = (): JSX.Element | null => {
    if (!title) return null;
    return first ? (
      <h1 className="text-4xl font-bold">{title}</h1>
    ) : (
      <h2 className="text-4xl font-bold">{title}</h2>
    );
  };

  const hasImage = imageUrl && imageUrl.length > 0;

  return (
    <SlideOuterContainer slideId={slideId}>
      <div className="w-full grid max-w-[70rem] md:px-16 min-h-content md:place-content-center">
        <div
          className={classNames({
            "grid grid-cols-1 md:grid-cols-2 gap-10": hasImage,
            "max-w-[40rem] place-self-center": !hasImage,
          })}
        >
          {hasImage && (
            <div className="w-full space-y-5 grid content-center">
              <img src={imageUrl} className="w-full" />
            </div>
          )}
          <div className="w-full space-y-10 min-h-content px-8 md:px-0 md:order-first grid content-center">
            <Title />
            {description && <div>{parse(description)}</div>}
          </div>
        </div>
      </div>
    </SlideOuterContainer>
  );
};

const socialsData = [
  {
    Icon: SMFacebookIcon,
    url: "https://www.facebook.com/olddubbogaol/",
  },
  {
    Icon: SMInstagramIcon,
    url: "https://www.instagram.com/olddubbogaol/?hl=en",
  },
  {
    Icon: SMTikTokIcon,
    url: "https://www.tiktok.com/@olddubbogaol",
  },
  {
    Icon: SMTripAdvisorIcon,
    url: "https://www.tripadvisor.com.au/Attraction_Review-g255320-d2097439-Reviews-Old_Dubbo_Gaol-Dubbo_New_South_Wales.html",
  },
  {
    Icon: SMGoogleIcon,
    url: "https://www.google.com.au/travel/entity/key/ChYIu42U8OWMzPJ6GgovbS8wNGcwbDBiEAQ/revie[…]BoECgIaACoECgAaAA&rp=OAFIAg&ap=MAA&ei=THjSYaW9LZjv9gTTyILgBw",
  },
];

const SocialsSlide = ({ slideId }: { slideId: string }): JSX.Element => (
  <SlideOuterContainer slideId={slideId}>
    <div className="md:text-center max-w-[30rem] flex flex-col gap-y-8 px-5 md:px-0">
      <h2 className="text-4xl font-black">Stay in touch</h2>
      <div>
        Don't forget to follow us on social media. With updates on events, tours
        and developments you'll never miss out!
      </div>
      <div>
        Let us know what you think! Have your say and leave us a review, let
        others know about your experience about the Old Dubbo Gaol.
      </div>
      <div>
        Visit our site www.olddubbogaol.com.au or contact us on{" "}
        <span className="whitespace-nowrap">(02) 6801 4460</span> or
        odg@dubbo.nsw.gov.au
      </div>
      <div className="flex justify-center gap-4 flex-wrap">
        {socialsData.map((sd) => (
          <a href={sd.url} target="_blank">
            <button className="h-12 w-12">
              <sd.Icon className="transition fill-white md:hover:fill-textDarkBgAlt" />
            </button>
          </a>
        ))}
      </div>
    </div>
  </SlideOuterContainer>
);

export { Slide, SocialsSlide };
