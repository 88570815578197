import { BrowserRouter as Router } from "react-router-dom";
import { DisplayRoute } from "./routes";
import { useMemo } from "react";
import { showWelcomePopupAtom } from "./atoms";
import { Helmet } from "react-helmet";

import _ from "lodash";
import { PageContainer } from "./components/layouts/PageContainer";
import WelcomePopup from "./components/WelcomePopup";
import { useAtom } from "jotai";

const RenderApp = () => {
  return (
    <>
      {/* {isSmall ? <MobileNavbar /> : <Navbar />} */}
      <PageContainer>
        <DisplayRoute />
      </PageContainer>
    </>
  );
};

function App() {
  // const [isLandscape] = useAtom(isLandscapeAtom);
  const [showPopup, setShowPopup] = useAtom(showWelcomePopupAtom);

  const RenderMemo = useMemo(() => <RenderApp />, []);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (showPopup === true) setShowPopup(false);
  //   }, 9000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  return (
    <>
      <Helmet>
        <title>Explore Old Dubbo Gaol</title>
        <meta name="robots" content="noindex" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta
          name="description"
          content="Discover the history and features of Old Dubbo Gaol"
        />
      </Helmet>
      <Router>{RenderMemo}</Router>
      {showPopup && <WelcomePopup closeExternal={setShowPopup} />}
      {/* {isMobile && isLandscape && (
        <>
          <div className="absolute top-0 bg-black w-full h-full grid place-content-center z-50">
            <div className="text-white w-42 h-5">
              This site is best viewed in portrait mode.
            </div>
          </div>
        </>
      )} */}
    </>
  );
}

export default App;
