const navbarData = [
  {
    label: "Map",
    to: "/",
  },
  {
    label: "Resources",
    to: "/resources",
  },
  {
    label: "About",
    to: "/about",
  },
];

export { navbarData };
