import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  TransformComponent,
  useControls,
  useTransformEffect,
} from "react-zoom-pan-pinch";
import { MapZoomButton } from "../Button";
import { useAtomValue } from "jotai";
import {
  displayInfoAtom,
  showWelcomePopupAtom,
  mapHeight,
  mapWidth,
  triggerRefreshAtom,
} from "../../atoms";
import { MarkerLine, MarkerTitle } from "./Marker";
import { ZoomInIcon, ZoomOutIcon } from "../Icons";
import { baseFilters } from "../MapFilters";
import { pause } from "../../utils";

const wrapperStyle = { width: "100%", height: "100%" };
const mapSrc = "/assets/map.jpg";

const TransformedComponent = ({
  data,
  minScale,
  setNode,
  currentNode,
  filter,
}: {
  filter: string;
  data: any[] | null;
  currentNode: any | null;
  setNode: Dispatch<SetStateAction<any | null>>;
  minScale: number;
}) => {
  const triggerRefresh = useAtomValue(triggerRefreshAtom);
  const { zoomIn, zoomOut, instance, zoomToElement, setTransform } =
    useControls();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  const [isMinScale, setIsMinScale] = useState<boolean>(true);
  const [isMaxScale, setIsMaxScale] = useState<boolean>(false);
  const displayInfo = useAtomValue(displayInfoAtom);
  const [curScale, setCurScale] = useState<number>(
    instance.transformState.scale
  );
  const showWelcomePopup = useAtomValue(showWelcomePopupAtom);

  const reset = () => {
    const xpos = displayInfo.wd * 0.5 - mapWidth * 0.5 * minScale;
    const ypos = displayInfo.ht * 0.5 - mapHeight * 0.5 * minScale;
    setTransform(xpos, ypos, minScale);
    zoomIn(0.01);
  };

  const delayLoad = async () => {
    try {
      await pause(300);
      reset();
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.onload = () => setIsMapLoaded(true);
    img.src = mapSrc;

    delayLoad();
  }, []);

  useEffect(() => {
    if (isLoaded) reset();
  }, [displayInfo, triggerRefresh, isLoaded]);

  useTransformEffect(({ state }) => {
    setCurScale(state.scale);
    const checkMinScale = state.scale === minScale;
    const checkMaxScale = state.scale === 1;
    if (checkMinScale !== isMinScale) setIsMinScale(checkMinScale);
    if (checkMaxScale !== isMaxScale) setIsMaxScale(checkMaxScale);
  });

  const dataWithMapPositions =
    data?.map((d) => {
      const markerX = (d.xPos / 100) * mapWidth;
      const markerY = (d.yPos / 100) * mapHeight;
      const showItem =
        filter === baseFilters[0] ||
        (filter === "Videos" && d.nodeType === "video") ||
        (filter === "Features" && d.nodeType === "feature") ||
        d.tags.find((tag: any) => tag.name === filter);
      return {
        ...d,
        markerX,
        markerY,
        hidden: !showItem,
      };
    }) || [];

  dataWithMapPositions.sort((a, b) => a.markerY - b.markerY);

  const handleSetNode = (d: any) => {
    setNode(d);
    zoomToElement(`node_${d.title}`, _.max([0.5, curScale]));
  };

  return (
    <div className="w-full h-full relative">
      <TransformComponent wrapperStyle={wrapperStyle}>
        {isLoaded && isMapLoaded && (
          <img
            src="/assets/map.jpg"
            style={{
              maxWidth: `${mapWidth}px`,
              // width: mapWidth,
              // height: mapHeight,
            }}
            className="animate animate-fadein bg-red-500"
            onLoad={() => reset()}
          />
        )}
        {isLoaded && (
          <div className="absolute z-10 left-0 top-0 w-full h-full pointer-events-none overflow-hidden">
            <div className="w-full h-full">
              {!showWelcomePopup &&
                dataWithMapPositions.map((d, index) => {
                  return d.hidden ? null : (
                    <MarkerLine
                      key={index}
                      xpos={d.markerX}
                      ypos={d.markerY}
                      curScale={instance.transformState.scale}
                    />
                  );
                })}
              {!showWelcomePopup &&
                dataWithMapPositions.map((d, index) => {
                  return d.hidden ? null : (
                    <MarkerTitle
                      key={index}
                      xpos={d.markerX}
                      ypos={d.markerY}
                      title={d.title}
                      onClick={() => handleSetNode(d)}
                      nodeType={d.nodeType}
                      isSelected={currentNode && d.id === currentNode.id}
                      curScale={instance.transformState.scale}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </TransformComponent>
      <div
        id="interface"
        className={"absolute inset-y-0 right-0 h-full pointer-events-none"}
      >
        <div className="h-full flex flex-wrap place-content-end md:place-content-center p-2 md:p-4 animate-fadeinMapControls ">
          <div id="zoomButtons" className="space-y-2 pointer-events-auto">
            <MapZoomButton disabled={isMaxScale} onClick={() => zoomIn(0.15)}>
              <ZoomInIcon strokeWidth={2} className="fill-none stroke-white" />
            </MapZoomButton>
            <MapZoomButton disabled={isMinScale} onClick={() => zoomOut(0.15)}>
              <ZoomOutIcon strokeWidth={2} className="fill-none stroke-white" />
            </MapZoomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransformedComponent;
