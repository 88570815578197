import classNames from "classnames";
import { twMerge } from "tailwind-merge";

/**
 * delay function for better visual response
 * @param ms milliseconds
 * @returns
 */
export const pause = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * correctly merge tailwind classes
 * @param args
 * @returns
 */
export const mergeClass = (...args: classNames.ArgumentArray) =>
  twMerge(classNames(args));
