import { useEffect, useState } from "react";
import SlideScroll from "../components/slidescroll/SlideScroll";
import axios from "axios";
import { Loader } from "../components/Loader";

const Resources = () => {
  const [data, setData] = useState<any[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        "https://lightwell-odg-site-syd.s3.ap-southeast-2.amazonaws.com/endpoints/resources.json"
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader isLoading />
      ) : (
        <SlideScroll slides={data || []} className="text-black" />
      )}
    </>
  );
};

export default Resources;
