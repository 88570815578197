import classNames from "classnames";
import React from "react";
import { mergeClass } from "../utils";

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly className?: string;
  readonly rel?: string;
}

const btnClass =
  "px-textBtn transition py-3 font-medium text-white bg-btnBgColor hover:bg-btnBgHover flex";

const Button = ({ children, ...restProps }: IButtonProps): JSX.Element => {
  return (
    <button className={btnClass} {...restProps}>
      {children}
    </button>
  );
};

const LinkButton = ({
  children,
  to,
  target,
  rel,
  ...restProps
}: IButtonProps & { to: string; target?: string }): JSX.Element => {
  return (
    <a href={to} target={target} rel={rel}>
      <button className={btnClass} {...restProps}>
        <div className="flex items-center">{children}</div>
      </button>
    </a>
  );
};

const CircleButton = ({
  children,
  className,
  ...restProps
}: IButtonProps): JSX.Element => {
  return (
    <button
      {...restProps}
      className={mergeClass(
        "w-10 h-10 transition rounded-full flex flex-wrap",
        "justify-center place-content-center disabled:opacity-50",
        "bg-darkGreen md:hover:bg-lightGreen",
        className
      )}
    >
      {children}
    </button>
  );
};

const MapZoomButton = ({
  children,
  className,
  disabled,
  ...restProps
}: IButtonProps): JSX.Element => {
  return (
    <button
      {...restProps}
      disabled={disabled}
      className={classNames(
        `w-10 h-10 transition rounded-full flex flex-wrap justify-center place-content-center bg-darkGreen`,
        {
          "opacity-70 pointer-events-none": disabled,
          "md:hover:bg-lightGreen": !disabled,
        },
        className
      )}
    >
      {children}
    </button>
  );
};

export { Button, LinkButton, CircleButton, MapZoomButton };
