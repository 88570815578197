import { useState } from "react";
import { Modal } from "..";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { navbarData } from "./navbarData";
import { NavLink } from "react-router-dom";
import { CloseIcon, MobileMenuIcon } from "../Icons";

const MobileNavbarLink = ({
  children,
  className,
  active,
  lightTheme = false,
  onClick,
  ...restProps
}: {
  to: string;
  children: React.ReactNode | string;
  target?: string;
  className?: string;
  active?: boolean;
  lightTheme?: boolean;
  onClick: () => void;
}) => {
  return (
    <NavLink
      className={twMerge(
        classNames(
          "transition font-bold text-3xl place-self-center",
          {
            "border-b-2 cursor-default": active,
            "text-black border-black": lightTheme,
            "md:hover:text-textDarkBgAlt": !active && !lightTheme,
            "md:hover:text-hoverGray": !active && lightTheme,
          },
          className
        )
      )}
      {...restProps}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};

export const MobileNavMenu = ({ altTheme }: { altTheme: boolean }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = window.location;
  return (
    <>
      <div className="flex justify-end">
        <button onClick={() => setIsOpen(!isOpen)}>
          <MobileMenuIcon
            stroke={altTheme ? "#333333" : "white"}
            width={36}
            height={36}
            strokeWidth={2}
            strokeLinecap="round"
          />
        </button>
      </div>
      {isOpen && (
        <Modal showModal={isOpen}>
          <Modal.Display tailwindWidth="w-full" tailwindHeight="h-full">
            <Modal.Body>
              <div
                className={twMerge(
                  classNames("w-full h-full relative grid", {
                    "bg-navBg1": !altTheme,
                    "bg-navBg2": altTheme,
                  })
                )}
              >
                <button
                  className="absolute top-0 right-0"
                  onClick={() => setIsOpen(false)}
                >
                  <CloseIcon
                    fill="none"
                    stroke={altTheme ? "black" : "white"}
                    width={55}
                    height={50}
                  />
                </button>
                <div className="place-self-center flex flex-col space-y-8">
                  {navbarData.map((link, index) => (
                    <MobileNavbarLink
                      to={link.to}
                      key={index}
                      active={location.pathname === link.to}
                      lightTheme={altTheme}
                      onClick={() => setIsOpen(false)}
                    >
                      {link.label}
                    </MobileNavbarLink>
                  ))}
                </div>
              </div>
            </Modal.Body>
          </Modal.Display>
        </Modal>
      )}
    </>
  );
};
