import { TransformWrapper } from "react-zoom-pan-pinch";
import { ZoomComponent } from "./ItemZoom";
import { VideoPlayer, IVideoPlaying } from "../VideoPlayer";
import { useEffect, useState } from "react";
import { Loader } from "../Loader";
import { CaptionBlock } from "./CaptionBlock";

const baseMaxScale = 2;
const baseMinScale = 0.5;

const ZoomItem = ({ src }: { src: string }) => (
  <TransformWrapper
    minScale={baseMinScale}
    maxScale={baseMaxScale}
    initialScale={baseMinScale}
  >
    <ZoomComponent
      minScale={baseMinScale}
      maxScale={baseMaxScale}
      src={src || ""}
    />
  </TransformWrapper>
);

const ThumbnailItem = ({ src, alt }: { src: string; alt?: string }) => {
  return (
    <div className="w-full h-full object-cover flex justify-center pointer-events-none">
      <img
        src={src}
        className={`h-full object-fit place-self-center`}
        // alt={alt}
      />
    </div>
  );
};

const BaseMediaItem = ({
  itemData,
  addBottomGradient,
  heightStyle,
  isZoom,
  playing,
  setPlaying,
}: {
  itemData: any;
  addBottomGradient: boolean;
  heightStyle: string;
  isZoom: boolean;
} & IVideoPlaying) => {
  const { type, fullImageUrl, thumbUrl, videoUrl, subtitleUrl } = itemData;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const urlForThumb = !isZoom
    ? thumbUrl || fullImageUrl || undefined
    : fullImageUrl || thumbUrl || undefined;
  useEffect(() => {
    setIsLoading(true);
    setPlaying(false);

    if (urlForThumb) {
      const img = new Image();
      img.onload = () => setIsLoading(false);
      img.src = urlForThumb;
    } else setIsLoading(false);
  }, [itemData]);

  const RenderItem = isLoading ? (
    <Loader isLoading />
  ) : type === "image" && !isZoom ? (
    <ThumbnailItem src={urlForThumb || ""} alt={itemData.caption || ""} />
  ) : type === "image" && isZoom ? (
    <ZoomItem src={urlForThumb || ""} />
  ) : type === "video" ? (
    <VideoPlayer
      thumbUrl={thumbUrl}
      videoUrl={videoUrl}
      playing={playing}
      setPlaying={setPlaying}
      className="w-full h-full"
      // subtitles={subtitleUrl}
    />
  ) : (
    <div className="w-full h-full grid text-center content-center">
      Unknown media type
    </div>
  );

  const showDebug = false;

  return (
    <div
      id="mediaItemContainer"
      className="w-full h-full relative bg-black"
      style={{ height: heightStyle }}
    >
      {RenderItem}
      {showDebug && (
        <div className="absolute top-0 left-0 text-xs text-white p-1 bg-black/50">
          <div>{`type: ${itemData.type}`}</div>
          <div>{`thumb: ${itemData.thumbUrl || `undefined`}`}</div>
          <div>{`mediaSrc: ${itemData.fullImageUrl || `undefined`}`}</div>
          <div>{`video: ${itemData.videoUrl || `undefined`}`}</div>
        </div>
      )}
      {isZoom && (
        <CaptionBlock caption={itemData?.caption} credit={itemData?.credit} />
      )}
    </div>
  );
};

export { BaseMediaItem };
