import classNames from "classnames";
import { SetStateAction } from "jotai";
import { type } from "os";
import { Dispatch, useState } from "react";
import ReactPlayer from "react-player";
import { VideoDefaultPlay } from "./Icons";

interface IVideoPlaying {
  playing: boolean;
  setPlaying: Dispatch<SetStateAction<boolean>>;
}

const VideoPlayer = ({
  thumbUrl,
  videoUrl,
  className,
  playing,
  setPlaying,
  subtitles,
}: {
  thumbUrl?: string;
  videoUrl: string;
  className?: string;
  subtitles?: string;
} & IVideoPlaying): JSX.Element => {
  console.log(subtitles);
  const config =
    !subtitles || subtitles.length < 2
      ? {
          file: { attributes: { controlsList: "nodownload" } },
        }
      : {
          file: {
            attributes: {
              controlsList: "nodownload",
              crossOrigin: "true",
            },
            // tracks: [
            //   {
            //     kind: "subtitles",
            //     src: subtitles,
            //     srcLang: "en",
            //     label: "Captions",
            //   },
            // ],
          },
        };

  return (
    <div onClick={() => setPlaying(!playing)} className={classNames(className)}>
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        url={videoUrl}
        playing={playing}
        onEnded={() => setPlaying(false)}
        controls={playing}
        config={config}
      />
      {thumbUrl && (
        <img
          src={thumbUrl}
          className={classNames(
            "transition w-full h-full object-cover absolute top-0 z-10 pointer-events-none",
            {
              "opacity-0": playing,
            }
          )}
        />
      )}
      {
        <div
          className={classNames(
            "absolute transition top-0 w-full h-full grid place-content-center bg-black/50 z-20 group",
            {
              "opacity-0 pointer-events-none": playing,
              "cursor-pointer": !playing,
            }
          )}
        >
          <VideoDefaultPlay
            className="transition fill-white md:group-hover:opacity-60"
            width={75}
            height={75}
          />
        </div>
      }
    </div>
  );
};

export { VideoPlayer };
export type { IVideoPlaying };
