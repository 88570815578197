import { Dispatch, SetStateAction, useState } from "react";
import Carousel from "nuka-carousel";
import { CircleButton } from "../Button";
import { NextIcon, HiResIcon, PrevIcon, VideoIcon } from "../Icons";
import { BaseMediaItem } from "./BaseMediaItem";
import { CaptionBlock } from "./CaptionBlock";
import classNames from "classnames";
import { mergeClass } from "../../utils";

export const ArrowButton = ({
  isLeft,
  onClick,
  scrollShift,
  display,
  isZoom,
}: {
  isLeft?: boolean;
  onClick: any;
  scrollShift?: boolean;
  isZoom?: boolean;
  display: boolean;
}) => {
  if (!display) return <></>;
  const svgProps = {
    width: 30,
    height: 30,
    stroke: "white",
    fill: "none",
    strokeWidth: 2,
  };
  return (
    <CircleButton
      className={classNames(`transition mx-2`, {
        "mx-4": isZoom,
      })}
      onClick={onClick}
    >
      {isLeft ? <PrevIcon {...svgProps} /> : <NextIcon {...svgProps} />}
    </CircleButton>
  );
};

const MediaCarousel = ({
  media,
  curItem,
  setShowZoom,
  setCurItem,
  slideHeightStyle,
  hasScroll,
  isZoom,
}: {
  media: any[];
  curItem: number;
  setCurItem: Dispatch<SetStateAction<number>>;
  setShowZoom: Dispatch<SetStateAction<boolean>>;
  slideHeightStyle: string;
  hasScroll: boolean;
  isZoom: boolean;
}) => {
  const single = media.length < 2;
  const config = {
    pagingDotsStyle: {
      fill: "white",
      padding: "0.4rem",
      transform: "scale(1.5)",
      zIndex: 10,
      filter: isZoom ? "drop-shadow(0 0 2px rgb(0 0 0 / 0.7))" : undefined,
    },
  };

  // TODO: Use this for different controls?
  // const isVideo = media[curItem]?.type === "video" || false;
  const [disableArrows, setDisableArrows] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const handleZoomClicked = () => {
    setShowZoom(true);
    setVideoPlaying(false);
  };

  const isVideo = media[curItem]?.type === "video";
  const swiping = !single && !videoPlaying && !isZoom;

  return (
    <div className={"w-full h-full relative z-10"}>
      {!isZoom && (
        <div
          className={mergeClass(
            "absolute pointer-events-none bottom-0 w-full left-0 h-[15%] bg-gradient-to-t from-black/70 z-[1]",
            { "opacity-0": videoPlaying }
          )}
        />
      )}
      <Carousel
        adaptiveHeight={false}
        style={{ height: "100%" }}
        dragging={swiping}
        swiping={swiping}
        slideIndex={curItem}
        afterSlide={(index) => {
          setDisableArrows(false);
          setCurItem(index);
        }}
        renderCenterRightControls={
          single || videoPlaying
            ? null
            : ({ nextSlide, currentSlide }) => (
                <ArrowButton
                  onClick={() => {
                    if (disableArrows) return;
                    setDisableArrows(true);
                    nextSlide();
                  }}
                  scrollShift={hasScroll}
                  isLeft={false}
                  display={currentSlide < media.length - 1}
                  isZoom={isZoom}
                />
              )
        }
        renderCenterLeftControls={
          single || videoPlaying
            ? null
            : ({ previousSlide, currentSlide }) => (
                <ArrowButton
                  onClick={() => {
                    if (disableArrows) return;
                    setDisableArrows(true);
                    previousSlide();
                  }}
                  isLeft
                  display={currentSlide > 0}
                  isZoom={isZoom}
                />
              )
        }
        withoutControls={single || videoPlaying}
        defaultControlsConfig={config}
      >
        {media.map((item, index) => (
          <BaseMediaItem
            key={index}
            itemData={item}
            addBottomGradient={media.length > 1}
            heightStyle={slideHeightStyle}
            isZoom={isZoom}
            playing={videoPlaying}
            setPlaying={setVideoPlaying}
          />
        ))}
      </Carousel>
      {!isZoom && !isVideo && (
        <CircleButton
          className={`transition absolute mr-2 mb-2 md:hover:opacity-70 right-0 bottom-0 z-10`}
          onClick={handleZoomClicked}
        >
          <HiResIcon
            width={30}
            height={30}
            stroke="white"
            fill="none"
            strokeWidth={2}
          />
        </CircleButton>
      )}
    </div>
  );
};

export default MediaCarousel;
