import classNames from "classnames";
import parse from "html-react-parser";
import { Dispatch, useEffect, useState } from "react";
import { SetStateAction, useAtom, useAtomValue } from "jotai";
import { CloseIcon } from "../Icons";
import { displayInfoAtom, isSmallAtom } from "../../atoms";
import MediaCarousel from "./MediaCarousel";
import ItemZoom from "./ItemZoom";
import { twMerge } from "tailwind-merge";
import { mergeClass } from "../../utils";

const desktopMediaHt = "20.25rem";

const ItemModal = ({
  data,
  showModal,
  clearNodeFunction,
}: {
  data: any;
  showModal: boolean;
  clearNodeFunction: Dispatch<SetStateAction<any | null>>;
}): JSX.Element | null => {
  const media = data?.mediaItems || [];
  const hasMedia = media.length > 0;

  const [showZoom, setShowZoom] = useState<boolean>(false);
  const [curItem, setCurItem] = useState<number>(0);
  const [displayInfo] = useAtom(displayInfoAtom);
  const isSmall = useAtomValue(isSmallAtom);
  const [isScroll, setIsScroll] = useState<boolean>(false);

  const calcMediaHt = (wd: number): number => wd * (9 / 16);

  const [mediaHt, setMediaHt] = useState<number>(calcMediaHt(displayInfo.wd));

  useEffect(() => {
    if (showModal) {
      // set media height
      setMediaHt(calcMediaHt(displayInfo.wd));

      // determine if scrolling
      const textHt = document.getElementById("body_text")?.clientHeight || 0;
      const containerHt =
        document.getElementById("body_container")?.clientHeight || 0;
      setIsScroll(textHt > containerHt);
    }
  }, [displayInfo, showModal]);

  useEffect(() => {
    setCurItem(0);
  }, [data]);

  if (!showModal) return null;
  const smallStyle = isSmall ? { height: mediaHt } : {};
  const isShort = displayInfo.ht < 800;

  return (
    <>
      {showZoom && (
        <ItemZoom
          showZoom={showZoom}
          setShowZoom={setShowZoom}
          mediaItem={media[curItem]}
          media={media}
          curItem={curItem}
          setCurItem={setCurItem}
        />
      )}
      <div
        className={mergeClass(
          "absolute top-0 left-0 h-full w-full pointer-events-none md:flex md:content-center"
        )}
      >
        <div
          className={mergeClass(
            "animate-fadein bg-white z-30 h-full",
            "w-full md:w-[36rem] drop-shadow-2xl md:max-h-[50rem] pointer-events-auto",
            {
              "md:ml-[3rem] md:h-[calc(100%-8rem)] place-self-center": !isShort,
              "md:h-[100%]": isShort,
            }
          )}
        >
          {hasMedia && (
            <div
              className={`w-full h-[${desktopMediaHt}] overflow-hidden`}
              style={smallStyle}
            >
              <MediaCarousel
                media={data.mediaItems}
                curItem={curItem}
                setCurItem={setCurItem}
                setShowZoom={setShowZoom}
                slideHeightStyle={isSmall ? `${mediaHt}px` : desktopMediaHt}
                hasScroll={false}
                isZoom={false}
              />
            </div>
          )}
          <div
            className={twMerge(
              classNames(`flex flex-col`, {
                "h-full": !hasMedia && !isSmall,
                "h-[calc(100%-20.25rem)]": hasMedia && !isSmall,
              })
            )}
            style={
              isSmall ? { height: `calc(100% - ${mediaHt}px)` } : undefined
            }
          >
            <h3 className="text-darkGreen text-3xl font-bold px-10 pt-6 pb-6 md:pt-10 md:pb-4">
              {data.title}
            </h3>
            <div
              id="body_container"
              className="w-full flex-grow text-black font-medium relative overflow-y-hidden"
            >
              <div
                id="body_text"
                className="w-full px-10 pt-4 pb-10 h-full overflow-y-auto"
              >
                {parse(data.description)}
              </div>
              <div
                id="top-gradient"
                className="absolute px-10 w-full h-4 left-0 top-0"
              >
                <div className="bg-gradient-to-b from-white w-full h-full" />
              </div>
              <div
                id="bot-gradient"
                className="absolute px-10 w-full h-4 left-0 bottom-0"
              >
                <div className="bg-gradient-to-t from-white w-full h-full" />
              </div>
            </div>
            <div
              id="close-button-section"
              className="w-full flex justify-center p-2 md:p-5"
            >
              <button
                className="flex text-darkGreen font-bold group"
                onClick={() => {
                  setCurItem(0);
                  clearNodeFunction(null);
                }}
              >
                <CloseIcon
                  strokeWidth={1.5}
                  width="40px"
                  height="40px"
                  fill="none"
                  className="transition stroke-darkGreen md:group-hover:stroke-lightGreen -ml-3"
                />{" "}
                <div className="transition grid content-center md:group-hover:text-lightGreen h-full">
                  Close
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ItemModal;
