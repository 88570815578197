import { SetStateAction, useAtomValue } from "jotai";
import { Modal } from "..";
import { Dispatch, useEffect, useState } from "react";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
  useTransformEffect,
} from "react-zoom-pan-pinch";
import { CloseIcon, ImageZoomInIcon, ImageZoomOutIcon } from "../Icons";
import { CircleButton } from "../Button";
import { CaptionBlock } from "./CaptionBlock";
import { Loader } from "../Loader";
import { pause } from "../../utils";
import MediaCarousel from "./MediaCarousel";
import { displayInfoAtom } from "../../atoms";

const baseMaxScale = 2;
const baseMinScale = 0.5;

export const ZoomComponent = ({
  src,
  minScale,
  maxScale,
}: {
  src: string;
  minScale: number;
  maxScale: number;
}) => {
  const [isMinScale, setIsMinScale] = useState<boolean>(false);
  const [isMaxScale, setIsMaxScale] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const displayInfo = useAtomValue(displayInfoAtom);
  const [imgSize, setImgSize] = useState<[number, number] | undefined>();

  useEffect(() => {
    const img = new Image();
    img.onload = () => updateImageSize(img.width, img.height);
    img.src = src;
  }, []);

  const updateImageSize = async (wd: number, ht: number) => {
    try {
      setIsLoading(true);
      setImgSize([wd, ht]);
      pause(500);
    } finally {
      setIsLoading(false);
    }
  };

  const { resetTransform, zoomIn, zoomOut, centerView } = useControls();

  useTransformEffect(({ state }) => {
    const checkMinScale = state.scale === minScale;
    const checkMaxScale = state.scale === maxScale;
    if (checkMinScale !== isMinScale) setIsMinScale(checkMinScale);
    if (checkMaxScale !== isMaxScale) setIsMaxScale(checkMaxScale);
  });

  useEffect(() => {
    centerView(baseMinScale, 0);
  }, [displayInfo]);

  const buttonStyle = {
    strokeWidth: 2,
    stroke: "white",
    fill: "none",
    width: 30,
    height: 30,
  };

  return (
    <div className="w-full h-full relative ss">
      <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
        {isLoading ? (
          <Loader isLoading />
        ) : (
          <div className="w-full h-full">
            <img
              src={src}
              className="w-full h-full object-fit"
              onLoad={() => resetTransform(0)}
            />
          </div>
        )}
      </TransformComponent>
      <div className="absolute bottom-4 right-4 group z-10 flex flex-col gap-3">
        <CircleButton onClick={() => zoomIn(0.25)} disabled={isMaxScale}>
          <ImageZoomInIcon {...buttonStyle} />
        </CircleButton>
        <CircleButton onClick={() => zoomOut(0.25)} disabled={isMinScale}>
          <ImageZoomOutIcon {...buttonStyle} />
        </CircleButton>
      </div>
    </div>
  );
};

const ItemZoom = ({
  mediaItem,
  showZoom,
  setShowZoom,
  media,
  curItem,
  setCurItem,
}: {
  mediaItem: any;
  showZoom: boolean;
  setShowZoom: Dispatch<SetStateAction<boolean>>;
  media: any[];
  curItem: number;
  setCurItem: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <Modal showModal={showZoom}>
      <Modal.Display
        tailwindWidth="w-full"
        tailwindHeight="h-full"
        useCloseBtn={false}
      >
        <Modal.Body>
          <div className="w-full h-full bg-black relative">
            <MediaCarousel
              media={media}
              curItem={curItem}
              setShowZoom={setShowZoom}
              setCurItem={setCurItem}
              slideHeightStyle={"100%"}
              hasScroll={false}
              isZoom
            />
            <CircleButton
              className="absolute top-4 right-4 group z-10"
              onClick={() => setShowZoom(false)}
            >
              <CloseIcon
                fill="none"
                strokeWidth={2}
                width={30}
                height={30}
                className="stroke-white"
              />
            </CircleButton>
          </div>
        </Modal.Body>
      </Modal.Display>
    </Modal>
  );
};
export default ItemZoom;
