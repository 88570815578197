import classNames from "classnames";
import _ from "lodash";
import {
  ButtonHTMLAttributes,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { FilterIcon, ZoomInIcon, ZoomOutIcon } from "./Icons";

const FilterButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { className, children, ...restProps } = props;
  return (
    <button
      className={classNames("w-full h-10 font-bold text-center", className)}
      {...restProps}
    >
      {children}
    </button>
  );
};

export const baseFilters = ["All", "Videos", "Features"];

const MapFilters = ({
  data,
  currentFilter,
  setFilters,
}: {
  data: any[];
  currentFilter: string | null;
  setFilters: Dispatch<SetStateAction<any>>;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const allFilters: string[] = data.reduce((arr, item) => {
    if (!item.tags) return arr;
    const itemTags: string[] = item.tags.map((t: any) => t.name);
    const dedupe = itemTags.filter((tag: string) => !_.includes(arr, tag));
    return [...arr, ...dedupe];
  }, baseFilters);

  return (
    <div className="absolute top-0 right-0 p-2 md:p-4 w-48 flex flex-col gap-2 text-sm">
      <FilterButton
        onClick={(e) => {
          e.preventDefault();
          setIsMenuOpen(!isMenuOpen);
        }}
        className="transition bg-navBg1 text-white flex place-content-center md:hover:bg-textLightBgAlt"
      >
        {isMenuOpen ? (
          <ZoomOutIcon
            id="noshadow"
            stroke="white"
            className="place-self-center"
            strokeWidth={2}
            width={32}
            height={32}
          />
        ) : (
          <ZoomInIcon
            id="noshadow"
            stroke="white"
            strokeWidth={2}
            className="place-self-center"
            width={32}
            height={32}
          />
        )}
        <div className="place-self-center">Filters</div>
      </FilterButton>
      {isMenuOpen &&
        allFilters.map((filter, index) => {
          const selected = currentFilter === filter;
          return (
            <FilterButton
              key={index}
              className={classNames(
                "flex place-content-center animate animate-fadein",
                {
                  "bg-white text-[#333] group md:hover:text-white md:hover:bg-darkGray transition":
                    !selected,
                  "bg-filterSelected text-white": selected,
                }
              )}
              disabled={selected}
              onClick={(e) => {
                e.preventDefault();
                if (selected) setFilters(null);
                else setFilters(filter);
              }}
            >
              <FilterIcon filter={filter} selected={selected} />
              <div className="place-self-center">{filter}</div>
            </FilterButton>
          );
        })}
    </div>
  );
};

export default MapFilters;
