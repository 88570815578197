import React from "react";
import { Route, Routes } from "react-router-dom";
import Map from "../pages/MapPage";
import About from "../pages/About";
import Resources from "../pages/Resources";
import ErrorPage from "../pages/404";
import Test from "../pages/Test";

const DisplayRoute = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" Component={Map} />
      <Route path="/resources" Component={Resources} />
      <Route path="/about" Component={About} />
      {/* <Route path="/test" Component={Test} /> */}
      <Route path="/*" Component={ErrorPage} />
    </Routes>
  );
};

export { DisplayRoute };
