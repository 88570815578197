import classNames from "classnames";
import { ReactComponent as FeaturedIcon } from "../assets/icons/icon_featured.svg";
import { ReactComponent as VideoIcon } from "../assets/icons/icon_video.svg";
import { ReactComponent as HistoryIcon } from "../assets/icons/icon_history.svg";
import { ReactComponent as KidsIcon } from "../assets/icons/icon_kids.svg";

export { ReactComponent as ZoomInIcon } from "../assets/icons/map_zoom_in.svg";
export { ReactComponent as ZoomOutIcon } from "../assets/icons/map_zoom_out.svg";
export { ReactComponent as ImageZoomInIcon } from "../assets/icons/image_zoom_in.svg";
export { ReactComponent as ImageZoomOutIcon } from "../assets/icons/image_zoom_out.svg";
export { ReactComponent as ImageCaptionIcon } from "../assets/icons/image_captions.svg";
export { ReactComponent as ArrowIcon } from "../assets/icons/arrow.svg";
export { ReactComponent as ArrowWelcomeIcon } from "../assets/icons/arrow_welcome.svg";
export { ReactComponent as PrevIcon } from "../assets/icons/image_back.svg";
export { ReactComponent as NextIcon } from "../assets/icons/image_next.svg";
export { ReactComponent as CloseIcon } from "../assets/icons/button_close.svg";
export { ReactComponent as HiResIcon } from "../assets/icons/carousel_high_res.svg";
export { ReactComponent as SMFacebookIcon } from "../assets/icons/social_media_facebook.svg";
export { ReactComponent as SMGoogleIcon } from "../assets/icons/social_media_google.svg";
export { ReactComponent as SMInstagramIcon } from "../assets/icons/social_media_instagram.svg";
export { ReactComponent as SMTikTokIcon } from "../assets/icons/social_media_ticktock.svg";
export { ReactComponent as SMTripAdvisorIcon } from "../assets/icons/social_media_trip_advisor.svg";
export { ReactComponent as Logo } from "../assets/icons/Logo.svg";
export { ReactComponent as VideoDefaultPlay } from "../assets/icons/video_default_play.svg";
export { ReactComponent as MobileMenuIcon } from "../assets/icons/mobile_menu.svg";

export { FeaturedIcon, VideoIcon, KidsIcon, HistoryIcon };

const filterIconProps = { width: 32, height: 32 };
const Icons = {
  Videos: VideoIcon,
  Features: FeaturedIcon,
};

const FilterIcon = ({
  filter,
  selected,
}: {
  selected: boolean;
  filter: string;
}) => {
  if (filter in Icons) {
    const Icon = Icons[filter as keyof typeof Icons];
    return (
      <Icon
        className={classNames("transition place-self-center -ml-1.5", {
          "fill-white": selected,
          "md:group-hover:fill-white": !selected,
        })}
        {...filterIconProps}
      />
    );
  }
  return <></>;
};

export { FilterIcon };
