import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="w-full h-full grid place-content-center text-center bg-black gap-2">
      <div>Oops! This is the wrong page.</div>
      <div className="text-darkGreen md:hover:text-lightGreen underline">
        <Link to={"/"}>Return to map</Link>
      </div>
    </div>
  );
};

export default ErrorPage;
