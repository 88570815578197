import TransformedComponent from "../components/map/MapComponent";
import { TransformWrapper } from "react-zoom-pan-pinch";
import { useState, useEffect } from "react";
import axios from "axios";
import ItemModal from "../components/ItemModal/ItemModal";
import { useAtomValue } from "jotai";
import {
  displayInfoAtom,
  mapHeight,
  mapWidth,
  triggerRefreshAtom,
} from "../atoms";
import MapFilters, { baseFilters } from "../components/MapFilters";

const MapPage = () => {
  const [data, setData] = useState<any[]>([]);
  const [currentNode, setCurrentNode] = useState<any | null>(null);
  const displayInfo = useAtomValue(displayInfoAtom);
  const triggerRefresh = useAtomValue(triggerRefreshAtom);
  const [filter, setFilter] = useState<string>(baseFilters[0]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCurrentNode(null);
    setFilter(baseFilters[0]);
  }, [triggerRefresh]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://lightwell-odg-site-syd.s3.ap-southeast-2.amazonaws.com/endpoints/featureNodes.json"
      );
      // console.log("map data", response.data);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="w-full h-full relative bg-repeat"
      id="container"
      style={{ backgroundImage: "url(/assets/map_tile.png)" }}
    >
      <TransformWrapper
        maxScale={1}
        minScale={displayInfo.minScale}
        initialScale={displayInfo.minScale}
        initialPositionX={
          displayInfo.wd * 0.5 - mapWidth * 0.5 * displayInfo.minScale
        }
        initialPositionY={
          displayInfo.ht * 0.5 - mapHeight * 0.5 * displayInfo.minScale
        }
      >
        <TransformedComponent
          minScale={displayInfo.minScale}
          data={data}
          setNode={setCurrentNode}
          currentNode={currentNode}
          filter={filter}
        />
      </TransformWrapper>
      <MapFilters
        data={data || []}
        currentFilter={filter}
        setFilters={setFilter}
      />
      <ItemModal
        data={currentNode}
        showModal={currentNode !== null}
        clearNodeFunction={setCurrentNode}
      />
    </div>
  );
};

export default MapPage;
